import { cva } from "class-variance-authority";
import { RadiusSizes } from "../../../constants";
import { BorderRadiusType, SizeType } from "../../../types";
import Image from "next/image";

interface PropTypes {
  imageUrl: string;
  size?: SizeType | "full";
  radius?: BorderRadiusType;
  available?: boolean;
}

export const CardImage = ({ imageUrl, size, radius }: PropTypes) => {
  // @ts-ignore
  return <Image src={imageUrl} className={imageVariants({ size, radius })} alt="" />;
};

const imageVariants = cva("h-full object-cover", {
  variants: {
    size: {
      xs: "w-8 h-8",
      sm: "w-24 h-24",
      md: "w-32 h-32",
      lg: "w-48 h-48",
      xl: "w-64 h-64",
      full: "w-full",
    },
    radius: RadiusSizes,
  },
});
