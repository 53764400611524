import { cva } from "class-variance-authority";
import { PropsWithChildren, type FC, type ReactElement } from "react";
import { RadiusSizes } from "../../constants";
import { SizeType } from "../../types";

interface ButtonProps {
  font?: string;
  fontWeight?: string;
  variant?:
    | "yellow_green"
    | "dangerRed"
    | "primary"
    | "primary_transparent"
    | "disabled"
    | "light_green_outline"
    | "secondary"
    | "black"
    | "secondary_outline"
    | "secondary_transparent"
    | "primary_outline"
    | "primary_outline_2"
    | "light"
    | "light_outline"
    | "light_gray"
    | "light_blue"
    | "blue_outline"
    | "orange_outline"
    | "red_outline";
  size?: SizeType;
  radius?: SizeType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  isLogout?: boolean;
  loading?: boolean;
}
let isLogoutButton = false;
export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  variant = "primary",
  font,
  fontWeight,
  size = "sm",
  radius,
  type,
  onClick,
  disabled,
  isLogout = false,
}): ReactElement => {
  isLogoutButton = isLogout;
  return (
    <button
      type={type}
      onClick={onClick}
      // @ts-ignore
      className={`md:w-max w-full text-nowrap rounded-md ${buttonVariants({ variant, size, radius })} ${font} ${fontWeight} ${disabled ? 'opacity-40': 'opacity-100'}`}
      disabled={disabled}
      // Dodano da se spriječi promjena boje kada je gumb onemogućen
      style={disabled ? { pointerEvents: 'none' } : {}}
    >
      {children}
    </button>
  );
};
const buttonVariants = cva("flex w-full flex-row items-center justify-center gap-3 hover:opacity-80 duration-300 px-6", {
  variants: {
    variant: {
      dangerRed: `border-2 border-[#de0a26] bg-[#de0a26] text-white ${isLogoutButton ? "w-full" : ""}`,
      primary: "border-2 border-primary bg-primary text-white",
      primary_outline: "border-2 border-primary bg-transparent text-primary",
      primary_transparent: "bg-transparent text-primary",
      secondary: "border-2 border-secondary bg-primary text-white",
      secondary_outline: "border-2 border-secondary bg-transparent text-secondary",
      secondary_transparent: "bg-transparent text-secondary",
      black: "border-2 border-black bg-black text-white",
      light: "border-2 border-light-button-white bg-light-button-white text-light-button-black",
      light_outline: "border-2 border-light-button-white bg-transparent text-light-button-white",
      light_gray: "border-2 border-light-gray bg-light-gray text-black",
      light_blue: "bg-light-blue text-blue",
      blue_outline: "bg-transparent text-blue",
      orange_outline: "bg-transparent text-warning",
      red_outline: "bg-transparent text-exit",
      light_green_outline: " bg-transparent text-primary",
      yellow_green: "bg-[#B7C60A] text-white !w-full",
      primary_outline_2: "border-2 border-primary bg-transparent text-primary !w-full",
      disabled: "bg-gray-300 text-gray-500",
    },
    size: {
      none: ["text-sm md:text-base", "py-0", "!px-0", "!justify-end", "w-full"],
      x: ["text-sm2", "py-1", "px-1"],
      xs: ["text-sm", "py-2", "px-2"],
      sm: ["text-sm2", "py-3", "px-3"],
      md: ["text-sm2", "py-3", "px-4"],
      lg: ["text-md", "py-4", "px-6"],
      xl: ["text-lg", "py-3", "px-6"],
    },
    radius: RadiusSizes,
  },
});
