'use client'
import { useState } from "react"
import { AccordionItem } from "./AccordionItem"

interface PropTypes {
    data: {
        answer: string,
        question: string
    }[],
}

export const Accordion = ({ data }: PropTypes) => {
    const [active, setActive] = useState<number | null>(null)

    const toggle = (index: number | null) => {
        setActive((prevState) => prevState === index ? null : index)
    }

    return data.map(({ answer, question }, index) => (
        <AccordionItem question={question} answer={answer} index={index} active={active} toggle={toggle} key={index} />
    ))
}