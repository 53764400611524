import Image from "next/image"

interface PropTypes {
    children: React.ReactNode,
    reverse?: boolean,
    image: string,
    list?: {
        icon: string,
        text: string
    }[]
}

export const BenefitSection = ({ image, list, children, reverse = false }: PropTypes) => {
    return (
        <section>
            <div className="md:container md:xl md:mx-auto">
                <div className={`flex flex-col lg:flex-row items-center justify-center ${reverse ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className="w-full flex flex-col order-2 px-8 py-12">
                        {children}
                        <ul className="flex flex-col gap-7">
                            {list?.map(({ icon, text }, index) => (
                                <li key={index} className="flex items-center gap-2 text-md font-medium"><Image src={icon} alt="Checkmark icon" />{text}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="w-full flex flex-col items-center">
                        <Image className="w-full h-96 lg:h-96 object-cover xl:w-3/4 xl:h-full rounded-xl" src={image} alt="benefit_section_image" />
                    </div>
                </div>
            </div>
        </section>
    )
}