import Link from 'next/link';
import { IconType as Icon } from 'react-icons';

interface PropTypes {
    children?: React.ReactNode,
    data?: {
        socialMedia: {
            icon: Icon,
            link: string,
        }[]
    }
}

export const Footer = ({ children, data }: PropTypes) => {
    return (
        <footer className='px-10'>
            <div className="xl:container md:mx-auto">
                <div className="flex flex-col gap-5">
                    {children}
                    <div className="flex text-center flex-col gap-4 lg:flex-row md:flex-row items-center justify-between border-t-2 border-footer-border py-5">
                        <p className='text-xs font-light'>All rights reserved <Link target='_blank' href='https://visiot.net/' className='text-primary'>visiot.net</Link> | <Link href='/' className='underline'>Uslovi korištenja</Link> i <Link href='/' className='underline'>Uslovi privatnosti</Link></p>
                        <div className='flex gap-5'>
                            {data?.socialMedia.map(({ icon: Icon, link }, index: number) => (
                                <Link href={link} target="_blank" className="bg-black p-2 rounded-full" key={index}>
                                    <Icon className='text-white text-md' />
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
