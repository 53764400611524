"use client";
import { cva } from "class-variance-authority";
import { type FC, type ReactElement, useState, useEffect } from "react";
import { FontWeights, RadiusSizes, TextSizes } from "../../constants";
import { FontWeightType, SizeType } from "../../types";
import { clsx } from "clsx";
import withSelectState from "./withSelectState";
import { UseFormRegister, FieldValues, RegisterOptions } from "react-hook-form";

interface SelectProps {
  data: {
    data: {
      name: string;
      value: string;
      serviceName?: string;
      firstName?: string;
      lastName?: string;
      duration?: string;
      id: string;
    }[];
  };
  label?: string;
  font?: string;
  variant?: "dark" | "light";
  size?: SizeType;
  radius?: SizeType;
  icon: React.ReactNode;
  labelWeight?: FontWeightType;
  labelSize?: SizeType;
  selected: RegisterOptions<FieldValues, string> | undefined;
  setSelected: (selected: string | number) => void;
  onChange: (value: string | undefined, name: string) => void;
  name: string;
  register: UseFormRegister<FieldValues>;
  selectedValue: string | number;
  selectedName: string;
  setSelectedName: (selectedName: string | number | undefined) => void;
  setValue: (name: string, value: string | undefined) => void;
}

const Select: FC<SelectProps> = ({
  data,
  variant = "dark",
  size = "md",
  radius,
  icon,
  label,
  font,
  labelSize,
  labelWeight,
  selected,
  selectedValue,
  setSelected,
  selectedName,
  setSelectedName,
  register,
  onChange,
  name,
  setValue,
}): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    register(name, selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    setSelected(selectedValue);
    setSelectedName(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <div className={`relative flex flex-col w-full select-none gap-2`}>
      {/* @ts-ignore */}
      {label && <span className={clsx(labelVariants({ labelWeight, labelSize }), font)}>{label}</span>}
      <div
        //   @ts-ignore
        className={clsx(selectVariants({ variant, size, radius }), font)}
        onClick={() => setIsOpen((prevState: boolean) => !prevState)}
        role="button"
      >
        {selectedName === "" ? "Odaberite" : selectedName} {icon}
      </div>
      {isOpen && (
        <ul className="absolute top-[4.9rem] z-20 bg-white overflow-y-scroll max-h-60 border w-full shadow-md">
          <li
            //     @ts-ignore
            className={clsx(optionVariants({ size }), font)}
            onClick={() => {
              setSelected("");
              setSelectedName("");
              setIsOpen((prevState: boolean) => !prevState);
              setValue(name, undefined);
            }}
          >
            Odaberite
          </li>
          {data?.data?.map((item) => (
            <li
              //     @ts-ignore
              className={clsx(optionVariants({ size }), font)}
              key={item.id}
              onClick={() => {
                setSelected(item.id);
                setSelectedName(item.serviceName ?? (item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : null) ?? item.duration);
                setIsOpen((prevState: boolean) => !prevState);
                onChange(
                  item.serviceName ?? (item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : null) ?? item.duration,
                  item.name
                );
              }}
            >
              {item.serviceName ?? (item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : null) ?? item.duration}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const labelVariants = cva("", {
  variants: {
    labelWeight: FontWeights,
    labelSize: TextSizes,
  },
});

const optionVariants = cva("border cursor-pointer hover:bg-select-hover", {
  variants: {
    size: {
      xs: "text-xs px-3 py-1",
      sm: "text-sm px-3 py-2",
      md: "text-sm px-3 py-3",
      lg: "text-md px-3 py-3",
      xl: "text-md px-3 py-3",
    },
  },
});

const selectVariants = cva("border w-full flex justify-between items-center cursor-pointer", {
  variants: {
    variant: {
      dark: "border-input-dark-border",
      light: "border-input-light-border",
    },
    size: {
      xs: "text-xs px-3 py-1",
      sm: "text-sm px-3 py-2",
      md: "text-sm px-3 py-3",
      lg: "text-md px-3 py-3",
      xl: "text-md px-3 py-3",
    },
    radius: RadiusSizes,
  },
});

const SelectWithState = withSelectState(Select);

export { SelectWithState };
