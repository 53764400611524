
import { IoMdAlert, IoMdPricetag } from "react-icons/io";
import { FaUserDoctor, FaUserInjured } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { BsWatch } from 'react-icons/bs';

interface PropTypes {
    date: string,
    time: string,
    serviceType: string,
    name: string,
    phoneNumber: string,
    mail: string,
    doctorName: string,
    address: string,
}

export const ReservationCard = ({ date, time, serviceType, name, phoneNumber, mail, doctorName, address }: PropTypes) => {
    return (
        <div className="bg-secondary w-full rounded-xl">
            <div className="bg-primary w-full flex justify-between p-5 rounded-t-xl text-white">
                <p className='flex gap-2 items-center text-sm font-semibold'><IoMdPricetag className='text-icon-disabled text-lg' />{date}</p>
                <p className='flex gap-2 items-center text-sm font-semibold'><BsWatch className='text-icon-disabled text-lg' />{time}</p>
            </div>
            <div className='p-4'>
                <div className='flex flex-col md:grid md:grid-cols-2 md:justify-end px-4 py-2 gap-8 text-primary font-semibold'>
                    <p className='flex items-start gap-2'><IoMdAlert className='text-lg' /> {serviceType}</p>
                    <div className='flex items-start gap-2 justify-self-end'><FaUserInjured className='text-xl' />
                        <div className="flex flex-col">
                            <span>{name}</span>
                            <span>{phoneNumber}</span>
                            <span>{mail}</span>
                        </div>
                    </div>
                    <p className='flex items-center gap-2'><FaUserDoctor className='text-lg' /> {doctorName}</p>
                    <p className='flex items-center gap-2 justify-self-end'><FaLocationDot className='text-lg' /> {address}</p>
                </div>
            </div>
        </div>
    )
}