import Image from "next/image";
import { MobileNav } from "./mobile-nav";
import { NavLink } from "./nav-link";

interface PropTypes {
  children?: React.ReactNode;
  image: string;
  alt: string;
  links: {
    name: string;
    pathname: string;
  }[];
}

export const Nav = ({ children, links, image, alt }: PropTypes) => {
  return (
    <nav className="p-5 fixed lg:relative w-full z-50 bg-white shadow-md lg:shadow-none">
      <div className="xl:container md:mx-auto">
        <div className="flex items-center justify-between">
          <Image src={image} alt={alt} />
          <ul className="flex hidden items-center gap-10 sm:hidden lg:flex">
            {links.map(({ name, pathname }, index) => (
              <NavLink linkName={name} linkPathname={pathname} key={index} />
            ))}
          </ul>
          {children}

          <MobileNav
            links={links}
            // eslint-disable-next-line react/no-children-prop
            children={children}
          />
        </div>
      </div>
    </nav>
  );
};
