import { cva } from "class-variance-authority";
import { FontWeights, TextSizes } from "../../../constants";
import { FontWeightType, SizeType } from "../../../types";

interface PropTypes {
  children: React.ReactNode;
  size?: SizeType;
  color?: "light-grey" | "primary" | "black" | "white";
  weight?: FontWeightType;
}

export const CardText = ({ children, size, color, weight }: PropTypes) => {
  // @ts-ignore
  return <p className={textVariants({ size, weight, color })}>{children}</p>;
};

const textVariants = cva("relative", {
  variants: {
    size: TextSizes,
    color: {
      "light-grey": "text-light-grey opacity-60",
      primary: "text-primary",
      black: "text-black",
      white: "text-white",
    },
    weight: FontWeights,
  },
});
