import { IoMdPricetag } from "react-icons/io";
import { BsWatch } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";

interface PropTypes {
    price: string,
    duration: string,
    data: {
        name?: string,
        available?: string,
        days?: string,
        time?: string
    }[]
}

export const ScheduleCard = ({ data, price, duration }: PropTypes) => {
    return (
        <div className="bg-secondary w-full rounded-xl">
            <div className="bg-primary w-full flex justify-between p-5 rounded-t-xl text-white">
                <p className='flex gap-2 items-center text-sm font-semibold'><IoMdPricetag className='text-icon-disabled text-lg' />{price}</p>
                <p className='flex gap-2 items-center text-sm font-semibold'><BsWatch className='text-icon-disabled text-lg' />{duration}</p>
            </div>
            <div className='p-4'>
                <div className='flex justify-between px-4 py-2'>
                    <div className="flex flex-col gap-3">
                        <h4 className='flex items-center gap-2'><FaUserDoctor className='text-lg text-primary' /> <span className='text-text-disabled opacity-80 font-semibold text-sm'>Osoblje</span></h4>
                        <ul className='flex flex-col gap-2'>
                            {data.map(({ name, available }, index) => (
                                <li key={index} className='font-medium text-sm flex items-center gap-7 justify-between text-text-disabled'><span className='opacity-60'>{name}</span> <span className='opacity-90'>{available}</span></li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-col gap-3">
                        <h4 className='flex items-center gap-2'><FaCalendarAlt className='text-lg text-primary' /> <span className='text-text-disabled opacity-80 font-semibold text-sm'>Dostupno</span></h4>
                        <ul className='flex flex-col gap-2'>
                            {data.map(({ time, days }, index) => (
                                <li key={index} className='font-medium text-sm flex items-center justify-between gap-10 text-text-disabled'><span className='opacity-60'>{days}</span> <span className='opacity-90'>{time}</span></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}