import { cva } from "class-variance-authority";
import { FontWeights, TextSizes } from "../../../constants";
import { FontWeightType, SizeType } from "../../../types";

interface PropTypes {
  children: React.ReactNode;
  weight?: FontWeightType;
  size?: SizeType;
  color?: "light-grey" | "primary" | "black";
}

export const CardTitle = ({ children, weight, size, color }: PropTypes) => {
  // @ts-ignore
  return <h3 className={titleVariants({ weight, size, color })}>{children}</h3>;
};

const titleVariants = cva("flex flex-col", {
  variants: {
    weight: FontWeights,
    color: {
      "light-grey": "text-light-grey opacity-60",
      primary: "text-primary",
      black: "text-black",
    },
    size: TextSizes,
  },
});
