import { FC, ReactElement } from "react";
import { CardImage } from "./CardImage";
import { CardTitle } from "./CardTitle";
import { CardText } from "./CardText";
import { cva } from "class-variance-authority";
import { CardImageOverlay } from "./CardImageOverlay";
import { AlignPlaces, RadiusSizes, ShadowSizes } from "../../constants";
import { AlignType, BorderRadiusType, BoxShadowType } from "../../types";

interface CardProps {
  children?: React.ReactNode;
  size?: "custom" | "full";
  shadow?: BoxShadowType;
  padding?: "1" | "2" | "3" | "4" | "5";
  radius?: BorderRadiusType;
  bg?: "primary" | "light-blue" | "white" | "lightOrange" | "descriptionCol";
  flex?: "flex-row" | "flex-col";
  align?: AlignType;
}

interface CardComponent extends FC<CardProps> {
  Image: typeof CardImage;
  Title: typeof CardTitle;
  Text: typeof CardText;
  ImageOverlay: typeof CardImageOverlay;
}

export const Card: CardComponent = ({ children, size, shadow, padding, radius, bg, flex, align }): ReactElement => {
  return <div className={CardVariants({ size, shadow, padding, radius, bg, flex, align })}>{children}</div>;
};

Card.Image = CardImage;
Card.ImageOverlay = CardImageOverlay;
Card.Title = CardTitle;
Card.Text = CardText;

const CardVariants = cva("", {
  variants: {
    size: {
      full: "w-full h-full",
      custom: "min-w-max flex-1 md:flex-0 w-full md:w-[332px] h-max",
    },
    flex: {
      "flex-row": "flex flex-row items-center gap-3",
      "flex-col": "flex flex-col",
    },
    align: AlignPlaces,
    shadow: ShadowSizes,
    padding: {
      "1": "p-1",
      "2": "p-2",
      "3": "p-3",
      "4": "p-4",
      "5": "p-5",
    },
    radius: RadiusSizes,
    bg: {
      primary: "bg-primary",
      "light-blue": "bg-light-blue",
      white: "bg-white",
      lightOrange: "bg-[#FCDCD2]",
      descriptionCol: "bg-[#D8D9EA]",
    },
  },
});
