import Image from "next/image";

interface PropTypes {
    children?: React.ReactNode,
    image?: string,
    alt?: string
}

export const HeroSection = ({ children, image, alt }: PropTypes) => {
    return (
        <section className="w-full">
            <div className="container xl mx-auto">
                <div className="flex justify-center items-center gap-10">
                    <div className="flex flex-col gap-5 w-full">
                        {children}
                    </div>
                    {image && alt && (
                        <div className="w-full hidden lg:block">
                            <Image src={image} alt={alt} className="w-full" />
                        </div>
                    )}
                </div>
            </div>
        </section >
    )
}