import { TiArrowRight } from "react-icons/ti"
import { Button } from "../../button"

interface PropTypes {
    available: boolean
}

export const CardImageOverlay = ({ available = true }: PropTypes) => {
    return (
        <>
            {!available && (
                <div className="absolute inset-0 bg-dark-card-overlay opacity-75 transition-all flex items-center justify-center h-full rounded-xl"></div>
            )}
            {!available && (
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none w-[200px] mx-auto">
                    <Button variant="light" size="sm" radius="xl">Usluga nedostupna</Button>
                </div>
            )}
            <div className={`absolute inset-0 transition-all bg-light-card-overlay opacity-0 ${available ? 'hover:opacity-50' : ''} flex items-center justify-center h-full rounded-xl`}>
                <div className="relative z-5">
                    {available && (
                        <Button variant="light_outline" size="sm" radius="xl">Vidi više <TiArrowRight className="text-xl" /></Button>
                    )}
                </div>
            </div>
        </>
    )
}