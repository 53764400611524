import React from 'react';

interface PropTypes {
    children: React.ReactNode;
    imageUrl?: string;
    height?: string;
    overlay?: boolean;
    overlayColor?: string;
}

export const Header = ({ children, imageUrl, height, overlay, overlayColor }: PropTypes) => {
    return (
        <header className={`relative w-full bg-cover bg-center bg-no-repeat`} style={{ backgroundImage: `url('${imageUrl}')`, height }}>
            {overlay && (
                <div className={`w-full h-full absolute top-0 left-0`} style={{ backgroundColor: overlayColor }}>
                </div>
            )}
            <div className="relative z-2 h-full">
                {children}
            </div>
        </header>
    );
};
