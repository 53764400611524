import { type FC, type ReactElement } from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form'

interface TextareaProps {
    resize?: string,
    placeholder?: string,
    rows?: number
    id?: string,
    label?: string
    font?: string,
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    name: string,
    register?: UseFormRegister<FieldValues>
}

export const Textarea: FC<TextareaProps> = ({ resize, placeholder, rows, font, id, label, value, onChange, name, register }): ReactElement => {
    return (
        <div className="flex flex-col gap-2">
            <label htmlFor={id} className={font}>{label}</label>
            <textarea
                id={id}
                name={name}
                {...(register && register(name))}
                className={`resize-${resize} border border-input-light-border w-full focus:outline-none p-4 rounded-sm`}
                value={value}
                onChange={onChange}
                rows={rows}
                placeholder={placeholder}>
            </textarea>
        </div>
    )
}