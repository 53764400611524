import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";

interface PropTypes {
    question: string,
    answer: string,
    index: number,
    active: number | null,
    toggle: (prevState: number | null) => void
}

export const AccordionItem = ({ question, answer, index, active, toggle }: PropTypes) => {
    return (
        <div className={`rounded-md flex flex-col px-5 py-2 w-full select-none ${active === index ? 'bg-primary text-white' : 'bg-transparent'}`}>
            <div className="flex gap-12 items-center justify-between border-b-2 px-3 py-5 cursor-pointer" onClick={() => toggle(index)}>
                <li className="text-md font-medium">{question}</li>
                <span className="text-lg">{active === index ? <FiMinusCircle /> : <FiPlusCircle />}</span>
            </div>
            {active === index && (
                <div className="px-2 py-5">
                    <p className="text-md font-medium">{answer}</p>
                </div>
            )}
        </div>
    )
}