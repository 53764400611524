'use client'
import Link from "next/link"
import { usePathname } from "next/navigation";

interface PropTypes {
    linkName: string,
    linkPathname: string,
    setIsOpen?: (prevState: boolean) => void,
}

export const NavLink = ({ linkName, linkPathname, setIsOpen }: PropTypes) => {
    const pathname = usePathname();
    return pathname !== '/rezervisi-termin' && (
        <li><Link href={linkPathname} onClick={() => setIsOpen && setIsOpen(false)} className={pathname === linkPathname ? 'text-primary' : 'text-black hover:text-primary'}>{linkName}</Link></li>
    )
}