'use client'
import { FC, useState } from "react";

const withSelectState = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) => {
    type PropsWithoutSelected = Omit<P, 'selected' | 'setSelected' | 'selectedName' | 'setSelectedName'>;

    const WithSelectState: FC<PropsWithoutSelected> = (props) => {
        const [selected, setSelected] = useState<string>('');
        const [selectedName, setSelectedName] = useState<string>('');
        const [isOpen, setIsOpen] = useState(false);

        return <WrappedComponent {...props as P} selected={selected} setSelected={setSelected} isOpen={isOpen} setIsOpen={setIsOpen} selectedName={selectedName} setSelectedName={setSelectedName} />;
    };

    return WithSelectState;
};

export default withSelectState;