import { IconType as Icon } from "react-icons";

interface PropTypes {
    data: {
        icon: Icon,
        subject: string,
        content: string,
    }[]
}

export const VisitCard = ({ data }: PropTypes) => {
    return (
        <div className="p-10 bg-visit-card grid grid-cols-2 gap-10 rounded-xl">
            {data.map(({ icon: Icon, subject, content }, index: number) => (
                <div className="flex gap-2 even:justify-end" key={index}>
                    <span className="text-lg text-primary mt-1"><Icon /></span>
                    <div className="flex flex-col gap-1">
                        <h3 className="font-semibold text-sm">{subject}</h3>
                        <p className="text-visit-card-text opacity-70">{content}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default VisitCard;
