'use client'
import { TfiMenu } from "react-icons/tfi";
import { FaTimes } from 'react-icons/fa';
import { NavLink } from "./nav-link";
import { useState } from "react";
import { usePathname } from "next/navigation";

interface PropTypes {
    children: React.ReactNode,
    links: {
        name: string,
        pathname: string
    }[]
}

export const MobileNav = ({ links, children }: PropTypes) => {
    const [isOpen, setIsOpen] = useState(false);
    const pathname = usePathname();

    return (
        <div className="flex flex-col lg:hidden">
            <button className={`${pathname === '/rezervisi-termin' ? 'hidden' : 'text-xl lg:hidden sm:block block'}`} onClick={() => setIsOpen((prevState) => !prevState)}>{isOpen ? <FaTimes /> : <TfiMenu />}</button>
            {isOpen && (
                <div className="fixed top-24 left-0 w-full flex flex-col bg-white z-50 h-screen">
                    <ul className="flex flex-col gap-8 relative top-14 text-md font-medium w-3/4 mx-auto">
                        {links.map(({ name, pathname }, index) => (
                            <NavLink linkName={name} linkPathname={pathname} key={index} setIsOpen={setIsOpen} />
                        ))}
                        {children}
                    </ul>
                </div>
            )}
        </div>
    )
}